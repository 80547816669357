import React from "react"
import { INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql, StaticQuery } from "gatsby"
// Components
import GA from "../components/ga"
import InnerWrapper from "../components/innerWrapper"
import Layout from "../components/layout"
import Newsletter from "../components/newsLetter"
import Sidebar from "../components/sidebar"
import SEO from "../SEO"
import Hero from "../components/hero"
// import SidebarCart from "../components/sidebarCart"

// Styles
import styles from "./scss/about.module.scss"

const about = () => (
  <StaticQuery
    query={graphql`
      {
        contentfulAboutSection {
          title
          body {
            json
          }
          heroImage {
            title
            description
            file {
              url
            }
          }
        }
      }
    `}
    render={(data) => {
      const options = {
        renderNode: {
          [INLINES.HYPERLINK]: (node) => {
            if (node.data.uri.includes("player.vimeo.com/video")) {
              return (
                <iframe
                  title="Unique Title 001"
                  src={node.data.uri}
                  frameBorder="0"
                  allowFullScreen
                  loading="lazy"
                  width="100%"
                  height="100%"
                ></iframe>
              )
            } else if (
              node.data.uri.includes("https://www.youtube.com/embed/")
            ) {
              return (
                <span className="video-iframe">
                  <iframe
                    title="Youtube Video"
                    src={node.data.uri}
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    allowFullScreen
                    loading="lazy"
                  ></iframe>
                </span>
              )
            }
          },
          "embedded-asset-block": (node) => {
            const alt = node.data.target.fields.title["en-US"]
            const url = node.data.target.fields.file["en-US"].url
            return <img alt={alt} src={url} />
          },
        },
      }
      return (
        <>
          <GA />
          <Layout>
            {/* <SidebarCart /> */}
            <SEO title="Brophy Brothers | About" />
            <Hero
              text=""
              image={data.contentfulAboutSection.heroImage.file.url}
              buttons={false}
            />
            <section className={`section ${styles.about}`}>
              <div className={styles.container}>
                <InnerWrapper width={960}>
                  <div className="banner">
                    <h2 className="ribbon">
                      <span className="top">&nbsp;</span>
                      <span className="text">
                        {data.contentfulAboutSection.title}
                      </span>
                      <span className="bottom">&nbsp;</span>
                    </h2>
                  </div>
                  {documentToReactComponents(
                    data.contentfulAboutSection.body.json,
                    options
                  )}
                </InnerWrapper>
              </div>
            </section>
            <Newsletter />
            <Sidebar />
          </Layout>
        </>
      )
    }}
  />
)

export default about
